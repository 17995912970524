<template>
    <div class="footer-main">
        <div>公司地址：{{address}}&nbsp;&nbsp;&nbsp;&nbsp;热线电话：{{telno}}
            <br> Copyright ©{{domain}}  2022 -&nbsp;
            <a class="foot-icp" :href="icp_url" target="_blank">粤ICP备{{icp_number}}号</a>
        </div>
    </div>
</template>

<script>
  import '@/assets/styles/foot.css'

  export default {
    name: "FootBar",
    data() {
      return {
          "address": "深圳市南山区沙河西路深圳湾科技生态园9栋A座",
          "telno": "131 2008 2240",
          "domain": "djtodo.com",
          "icp_number": "2022084779",
          "icp_url": "http://beian.miit.gov.cn/"
      };
    }
  }
</script>