<template>
    <div class="headers-main">
        <img src="@/assets/images/header/logo.png" class="headers-logo">
        <div class="headers-menu">
          <el-menu router :default-active="activeIndex" mode="horizontal" @select="handleSelect"
           background-color="#332f3d" text-color="#fff" active-text-color="rgb(255, 208, 75)">
            <el-menu-item :key="key" v-for="(item,key) in leftNavItems" :index="item.path">
              {{item.name}}
            </el-menu-item>
            <el-submenu v-if="Object.keys(rightNavItems).length === 0 ? false:true" index="10">
              <template slot="title">
                <i class="el-icon-s-fold" ></i>
              </template>
              <el-menu-item :key="key" v-for="(item,key) in rightNavItems" :index="item.path">
                {{item.name}}
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
    </div>
</template>

<script>
  import '@/assets/styles/header.css'
  import { routerList } from '@/router'
  
  export default {
    name: "HeaderBar",
    data() {
      return {
        activeIndex: window.location.pathname,
        screenWidth: document.body.clientWidth,
        navItems: routerList
      };
    },
    methods: {
      handleSelect(key, keyPath) {
        // 路由跳转时，标记在导航的那个选项下对应的路由
        this.activeIndex = keyPath[0];
      }
    },
    mounted() {
      window.onresize = () => {
        this.screenWidth = document.body.clientWidth
      }
    },
    watch: {
      screenWidth(newValue) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = newValue;
          this.timer = true;
          setTimeout(() => {
          this.timer = false;
          }, 400);
        }
      }
    },
    computed: {
      leftNavItems: function() {
        return this.screenWidth >= 780 ? this.navItems : {};
      },
      rightNavItems: function() {
        return this.screenWidth < 780 ? this.navItems : {};
      }
    }
  }
</script>