<template>
  <div>
    <div v-if="isMobile">
      <img src="@/assets/images/index/index-mobile.png" class="index-img">
    </div>
    <div v-else>
      <img src="@/assets/images/index/index.png" class="index-img">
    </div>
    
  </div>
</template>


<script>
  import '@/assets/styles/firstPage.css'
  export default {
    name: "FirstPage",
    data() {
      return {
        isMobile: document.body.clientWidth < 780 ? true:false
      };
    }
  }
</script>