<template>
  <div id="app">
    <HeaderBar/>
    <router-view></router-view>
    <FootBar/>
  </div>
</template>

<script>
  import '@/assets/styles/app.css'
  import HeaderBar from './components/common/HeaderBar.vue'
  import FootBar from './components/common/FootBar.vue'

  export default {
    name: 'App',
    components: {
      HeaderBar,
      FootBar
    }
  }
</script>