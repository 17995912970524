<template>
    <div>
        <div class="product-img"></div>
        <div class="product-frame">
          <div class="product-title wow product-show">智慧场外</div>
          <div class="product-row">
            <div class="product-col wow product-show"><img class="product-img-auto" src="@/assets/images/techProduct/product-3.png"></div>
            <div class="product-col product-content wow product-show">
              场外交割支持有效整合各类型投资领域人工痕迹非常重的主要核心工作，
              通过不同金融科技技术的使用，有效覆盖到了投资、交易、运营各业务环节与场景，
              有效提升了上述领域的数字化与智能化水平
              <li>智能交割：提升全固收投资链效率与信息交互。</li>
              <li>新股通：高效进行新股全流程有效控制业务风险。</li>
              <li>定存易：有效进行流程管理提升定存文本规范。</li>
              <li>场外管家：赋能开基全流程打造数字化场景。</li>
            </div>
          </div>
        </div>
        <div class="product-frame">
          <div class="product-title wow product-show">智慧运营</div>
          <div class="product-row">
            <div class="product-col product-content wow product-show">
              数字化时代下，多样化的智慧运营产品将成为资管运营风险控制的有效利器，助力资管行业的稳步前进。
              <li>头寸管家：有效控制头寸投资风险。</li>
              <li>财务机器人：有效控制人为操作风险。</li>
              <li>权益雷达：有效控制公司行为风险。</li>
              <li>安心估值：有效控制估值差错风险。</li>
              <li>ETF机器人：有效控制清单制作流程风险。</li>
            </div>
            <div class="product-col wow product-show"><img class="product-img-auto" src="@/assets/images/techProduct/product-1.png"></div>
          </div>
        </div>
        <div class="product-frame">
          <div class="product-title wow product-show">智慧大脑</div>
          <div class="product-row">
            <div class="product-col wow product-show"><img class="product-img-auto" src="@/assets/images/techProduct/product-2.png"></div>
            <div class="product-col product-content wow product-show">前瞻性进行智慧运营产品规划，以业务系统建设、数据整合建设、
              场景落地建设、管理系统建设等多维度、分阶段、分层次进行产品研发，
              逐步全面覆盖资管各业务场景，促使智慧运营创造更多价值。</div>
          </div>
        </div>
    </div>
</template>


<script>
  import '@/assets/styles/techProduct.css'
  
  
  export default {
    name: "TechProduct",
    data() {
      return {};
    }
  }
</script>