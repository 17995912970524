<template>
    <div>
      <div class="recruit-img"></div>
      <el-row>
        <el-col :span="10" class="el-col-left wow product-show">
          <el-card>
            <div slot="header" class="card-header">
                <span>前端工程师</span>
            </div>
            <div>
              <p class="card-body">职位信息：</p>
              <p>1、负责涉及APP、小程序、网站等前端开发工作；<br/>
              2、产品运营的市场活动页面等相关开发支持；<br/>
              3、与中后台开发进行协同开发。</p>
              <p class="card-body">任职要求：</p>
              <p>1、本科或以上学历，计算机科学、软件工程等相关专业背景；<br/>
              2、熟练掌握react技术栈，包括redux；<br/>
              3、熟练掌握sass等css预处理语言；<br/>
              4、熟练掌握webpack等前端自动化构建技术；<br/>
              5、熟悉http协议，掌握一定程度的前端优化技术；<br/>
              6、熟悉各种跨浏览器兼容性技术，熟悉响应式布局和屏幕适配技术；<br/>
              7、对页面布局具有深刻理解，注重细节，善于改善产品的视觉感觉和用户体验；<br/>
              8、具有良好的沟通能力、团队合作精神、分析问题和解决问题的能力，工作认真、严谨、对系统质量有近乎苛刻的要求意识。</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="10" class="el-col-right wow product-show">
         <el-card>
            <div slot="header" class="card-header">
              <span>python工程师</span>
            </div>
            <div>
              <p class="card-body">职位信息：</p>
              <p>1、整合聚源、万得等外部数据，TA\FA\投资交易等内部系统数据;<br/>
              2、构建以API、报表、邮件等形式提供服务的数据中心。</p>
              <p class="card-body">任职要求：</p>
              <p>1、本科或以上学历，计算机科学、软件工程等相关专业背景，工作经验不少于三年，对新技术新应用有强烈的兴趣；<br/>
              2、熟悉python3，以及常用的框架例如sqlalchemy/pandas/numpy/flask；<br/>
              3、根据场景调研寻找合适的第三方库，且能进行简单的调试和改造；<br/>
              4、对设计数据仓库有一定的经验；<br/>
              5、会使用svn或git等源码管理工具；<br/>
              6、熟悉linux系统以及常用命令操作;<br/>
              7、了解xlwings的使用，与excel、word交互；<br/>
              8、使用过工作流调度平台，能部署上线；<br/>
              9、有稳定一致的代码风格，从功能、性能、运维等多角度推进项目。</p>
            </div>
         </el-card>
          
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10" class="el-col-left wow product-show">
          <el-card >
            <div slot="header" class="card-header">
                <span>测试工程师</span>
            </div>
            <div>
              <p class="card-body">职位信息：</p>
              <p>1、分析产品需求，能深入思考业务，提出自己的看法。编写测试用例、执行测试，参与需求评审，提出建设性意见，给出合理的测试策略；<br/>
              2、发现产品bug，提交并验证bug；<br/>
              3、对部分功能进行性能与安全性测试；<br/>
              4、参与版本上线测试验证，完成测试项目归纳及总结文档；<br/>
              5、对于客服反馈的运营环境的问题，及时定位问题并分析原因，跟进直到解决。</p>
              <p class="card-body">任职要求：</p>
              <p>1、大专以上，计算机专业,3年以上测试经验；<br/>
              2、熟悉软件测试体系及流程，掌握软件工程和测试理论知识、规范；有迭代测试经验，了解版本控制；<br/>
              3、熟练oracle数据库的使用，熟练掌握SQL语句；<br/>
              4、有较强的逻辑思维、业务理解能力，优秀的发现问题、分析问题、解决问题的能力；<br/>
              5、主动性强，有责任心，具有良好的沟通表达能力。<br/>
              6、具有系统功能全流程的测试经验。</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="10" class="el-col-right wow product-show">
         <el-card>
            <div slot="header" class="card-header">
              <span>etl开发/数据开发工程师</span>
            </div>
            <div>
              <p class="card-body">职位信息：</p>
              <p>1、参与BI类项目实施;<br/>
              2、负责数据仓库、数据集市、报表平台等BI项目的ETL开发、数据模型设计及应用开发；<br/>
              3、负责BI项目的报表设计及开发;<br/>
              4、针对数据仓库应用系统的数据处理需求，对金融行业（基金、银行、证券、保险)业务数据进行分析，完成数据仓库的数据抽取、转换和装载过程;<br/>
              5、参与数据仓库中ETL流程的优化以及数据仓库系统实施过程中ETL相关技术问题的解决。</p>
              <p class="card-body">任职要求：</p>
              <p>1、计算机、数学或者电子信息技术本科以上学历；<br/>
              2、2年以上BI从业经验；<br/>
              3、熟悉数据仓库架构及原理，熟悉LINUX系统；<br/>
              4、熟悉数据ETL原理，熟悉Oracle、Mysql数据库；<br/>
              5、熟练应用Airflow、Kellte、informatica等ETL工具；<br/>
              6、熟悉面向对象编程技术，良好的学习能力和逻辑思维能力。</p>
            </div>
         </el-card>
          
        </el-col>
      </el-row>

      <div class="deliver-way">
        <div class="deliver-way-1">
          <span>公司官网：</span>www.djtodo.com
        </div>
        <div class="deliver-way-1">
          <span>履历投递：</span>hr@djtodo.com
        </div>
        <div class="deliver-way-1">
          <span>投递格式：</span>名字-学校-应聘岗位
        </div>
      </div>
    </div>
</template>


<script>
  import '@/assets/styles/recruitPerson.css'
  import '@/assets/styles/techProduct.css'
  
  export default {
    name: "RecruitPerson",
    data() {
      return {};
    }
  }
</script>