import Vue from 'vue'
import Router from 'vue-router'
import FirstPage from '@/components/page/FirstPage'
import AboutUs from '@/components/page/AboutUs'
import TechProduct from '@/components/page/TechProduct'
import RecruitPerson from '@/components/page/RecruitPerson'


Vue.use(Router)

export const routerList = [
  {
    name: "首页",
    path: '/',
    component: FirstPage
  },
  {
    name: "关于我们",
    path: '/page/AboutUs',
    component: AboutUs
  },
  {
    name: "科技研究",
    path: '/page/TechProduct',
    component: TechProduct
  },
  {
    name: "加入我们",
    path: '/page/RecruitPerson',
    component: RecruitPerson
  }
]

export default new Router({
    mode: 'history', // 去掉url中的#
    routes: routerList
})