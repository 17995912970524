<template>
    <div>
      <div class="about-img"></div>
      <div class="about-company">
        <div class="about-company-title">公司简介</div>
        <div class="about-company-content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市点将创新科技有限公司（以下简称“点将创新”）成立于2016年12月，是为资产管理行业的投资、市场、运营管理领域提供
            智能化解决方案的服务供应商，力求实现资管全方位管理的智能化、电子化、信息化、生态化。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点将创新专注于资管行业的各领域痛点场景，以全面提升资管公司工作智能化水平、有效提升运营效率、
            节约综合运营成本、降低各类型业务风险为发展目标。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点将创新持续关注客户的体验，一方面，基于自身对业务的理解，持续进行系统的功能性优化升级，
            另一方面,将快速跟进客户的需求反馈，以持续提升产品的使用体验。<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点将创新在北京、上海、深圳均有专属产品服务团队，为客户提供全方位的专业服务。
        </div>
      </div>
      
      <div class="about-company">
        <div class="about-company-title">联系我们</div>
        <div class="about-company-content about-concant">
          <div class="about-flex">
            <img src="@/assets/images/aboutUs/telphone.png">
            <div class="about-alignment">131 2008 2240</div>
          </div>
          <div class="about-flex">
            <img  src="@/assets/images/aboutUs/email.png">
            <div class="about-alignment">support@djtodo.com</div>
          </div>
          <div class="about-flex">
            <img src="@/assets/images/aboutUs/address.png">
            <div class="about-alignment">深圳市南山区沙河西路深圳湾科技生态园9栋A座</div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
  import '@/assets/styles/aboutUs.css'

  export default {
    name: "AboutUs",
    data() {
      return {};
    }
  }
</script>